import {RestCache, RestService} from './rest.service';
import {environment} from '../global/app';
import {Observable} from "rxjs";
import {ICategory} from "../interfaces/category";

class CategoryServiceController extends RestService {

    constructor() {
        super();
        this.setApi(environment.REST_API);
    }

    public read(data?, cache?: RestCache): Observable<ICategory[]> {
        return super.read(`/categories`, data, cache);
    }


}


export const CategoryService = new CategoryServiceController();